// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：公用组件中.意向产品js
// 开始时间：2019-11-20
// 开发人员：吴荣江
// 最后修改：2019-11-20
// 备注说明：如需修改请联系开发人员
import popupLoading from '@/components/publicComponent/popupLoading'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
	name: "comProduct",
	data() {
		return {
			proTest: false, //弹窗
			p_currpage: 1, //当前页
			p_pagesize: 10, //一页显示的条数
			p_tolRecord: 0, //总条数
			choosePro: [], //选中的关联产品
			proInfos: [], //关联产品信息
			proInput: '', //关联产品搜索内容
			pageCount: 5,
			allProduct: false,//产品全选
			showChoiceProduct: [],//显示选中的产品
			loading: false,//产品加载
			curProduct: [],//当前打开产品选择框，加载过的产品信息
			curChoiceId: [],//当前全部产品选中的
			curPageId: [], // 当前具体某也之前被选中的产品id
		}
	},
	components: {
		popupLoading
	},
	computed: {
		...mapState({
			productlistall2: state => state.productlistall2,
		}),
	},
	props: {
		choiceProductArr: {
			default: []
		},
	},
	methods: {
		...mapMutations({
			getProductlistAll2: 'getProductlistAll2',
		}),
		//获取采购偏好数据集合
		getRelationProInfo() {
			let self = this
			this.proInfos = [];
			this.getProductlistAll2({
				currpage: this.p_currpage,
				pagesize: this.p_pagesize,
				conditionValue: this.proInput
			});
			if (this.productlistall2.productclassifylist != ""
				&& this.productlistall2.productclassifylist != null &&
				this.productlistall2.productclassifylist != undefined) {
				this.proInfos = this.productlistall2.productclassifylist;
				//吧浏览的信息存储在暂时的变量里面
				$.each(this.proInfos, (i, elem) => {
					self.curProduct.push({
						id: elem.id,
						name: elem.name,
						product_code: elem.product_code
					})
				})
				//判断在获取上一份被删除的产品id
				$.each(this.curPageId, (i, elem) => {
					let has = false
					$.each(self.choosePro, (b, row) => {
						if (row == elem) {
							has = true;
							return false;
						}
					})
					if (!has) {
						//从存储id中删除
						$.each(self.curChoiceId, (j, jelem) => {
							if (jelem == elem) {
								self.curChoiceId.splice(j, 1);
								return false;
							}
						})
					}
				})
				//增加选入的
				$.each(self.choosePro, (i, row) => {
					let has = false
					$.each(self.curChoiceId, (b, elem) => {
						if (elem == row) {
							has = true
							return false;
						}
					})
					if (!has) {
						self.curChoiceId.push(row)
					}
				})
				self.choosePro = []
				self.curPageId = []
				//进行产品的回显
				$.each(self.proInfos, (i, row) => {
					$.each(self.curChoiceId, (b, elem) => {
						if (Number(elem) == row.id) {
							self.choosePro.push(row.id);
							self.curPageId.push(row.id)
							return false;
						}
					})
				})
			}
			this.p_tolRecord = this.productlistall2.pageBean.totalRecord;
		},
		//重置
		reset_proInput() {
			this.p_currpage = 1;
			this.p_pagesize = 10;
			this.proInput = "";
			this.getRelationProInfo();
		},
		//搜索采购偏好方法
		searchProClick() {
			this.p_currpage = 1;
			this.p_pagesize = 10;
			this.proInfos = [];
			this.p_tolRecord = 1;
			//获取采购偏好
			this.getRelationProInfo();
		},
		//分页查询
		p_changeCurrentPage(page) {
			this.p_currpage = page;
			//获取采购偏好
			this.getRelationProInfo();
		},
		//条数查询
		p_changeSizePage(size) {
			this.p_pagesize = size;
			//获取采购偏好
			this.getRelationProInfo();
		},
		//确认搜索采购偏好
		submitProInfo() {
			let self = this
			//判断在获取上一份被删除的产品id
			$.each(this.curPageId, (i, elem) => {
				let has = false
				$.each(self.choosePro, (b, row) => {
					if (row == elem) {
						has = true;
						return false;
					}
				})
				if (!has) {
					//从存储id中删除
					$.each(self.curChoiceId, (j, jelem) => {
						if (jelem == elem) {
							self.curChoiceId.splice(j, 1);
							return false;
						}
					})
				}
			})
			$.each(self.choosePro, (i, row) => {
				let has = false
				$.each(self.curChoiceId, (b, elem) => {
					if (elem == row) {
						has = true
						return false;
					}
				})
				if (!has) {
					self.curChoiceId.push(row)
				}
			})
			if (this.curChoiceId == "") {
				this.$message({
					showClose: true,
					message: '请选择关联产品!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			//之前ajax请求加载过的产品信息
			$.each(this.showChoiceProduct, (i, elem) => {
				let has = false
				$.each(self.curProduct, (b, row) => {
					if (elem.id == row.id) {
						has = true
						return false;
					}
				})
				if (!has) {
					self.curProduct.push(elem)
				}
			})
			this.showChoiceProduct = []
			//获取选择的产品名称和产品编号
			$.each(this.curChoiceId, (index, row) => {
				$.each(self.curProduct, (b, elem) => {
					if (elem.id == row) {
						self.showChoiceProduct.push({
							id: elem.id,
							name: elem.name,
							code: elem.product_code
						})
						return false;
					}
				})
			});
			this.$emit('cofirmProduct', this.curChoiceId);
			//客户·客户详细信息·新建/编辑订单弹窗 (title：成交产品) 获取产品id和名称
			this.$emit("getProductInfo", self.showChoiceProduct)
			this.closePro()
		},
		//关闭弹窗
		closePro() {
			this.proTest = false
			this.choosePro = []
			this.curProduct = []
			this.curPageId = []
			this.curChoiceId = []
			this.allProduct = false
		},
		//显示产品弹框
		showProductBox() {
			//判断产品有无选择过
			let arr = []
			let arr2 = []
			//判断当前是数组还是string
			if (typeof this.choiceProductArr == 'string' ) {
				if (this.choiceProductArr.indexOf(",") != -1) {
					arr = this.choiceProductArr.split(",")
				} else {
					if (this.choiceProductArr != '' ) {
						arr.push(Number(this.choiceProductArr))
					}
				}
				$.each(arr, (i, elem) => {
					arr2.push(Number(elem))
				})
				this.curChoiceId = arr2
			} else if(this.choiceProductArr == 0){
				this.curChoiceId =[]
			}else {
				this.curChoiceId = this.choiceProductArr
			}
			this.getRelationProInfo();
			this.proTest = true
		},
		// 点击全选，获取当前展示的产品信息
		getAllProductInfo() {
			this.choosePro = []
			if (this.allProduct) {
				let self = this
				$.each(this.proInfos, function (i, row) {
					self.choosePro.push(row.id)
				})
			}
		},
		// 判断当前选中列表的数是否全部选择
		changeProductList(val) {
			//全选情况下 全选框显示为true
			// if (val.length == this.proInfos.length) {
			// 	this.allProduct = true
			// } else {
			// 	this.allProduct = false
			// }
		},
		//通过父组件返回的id来判断当前产品
		showProductData() {
			let self = this
			let arr = []
			let arr2 = []
			let index = 0
			self.showChoiceProduct = []
			//判断当前是数组还是string
			if (typeof this.choiceProductArr == 'string') {
				if (this.choiceProductArr.indexOf(",") != -1) {
					arr = this.choiceProductArr.split(",")
				} else {
					if (this.choiceProductArr != '') {
						arr.push(Number(this.choiceProductArr))
					}
				}
				$.each(arr, (i, elem) => {
					arr2.push(Number(elem))
				})
			} else {
				arr2 = this.choiceProductArr
			}
			if (arr2.length > 0) {
				this.loading = true
			}
			//循环当前的产品
			$.each(arr2, (i, elem) => {
				if (elem != 0) {
					$.ajax({
						type: 'POST',
						url: backUrl + 'cusXPCS_findXProductByid.action',
						data: {
							token: token,
							productclassifyid: Number(elem)
						},
						success: (res) => {
							if (res !== undefined && res !== null && res != '') {
								self.showChoiceProduct.push({
									id: res.id,
									name: res.name,
									code: res.product_code
								})
							}
							index += 1
							if (index == arr.length) {
								self.loading = false
							}
						}
					})
				} else {
					index += 1
					if (index == arr.length) {
						self.loading = false
					}
				}
			})
		},
	},
	mounted: function () {
		function clearTransform() {
			$('.el-dialog').css({
				'transform': 'none',
			})
		};
		dialogDrag();
		clearTransform(dialogCenter());
		this.getRelationProInfo();
		//获取选中的产品
		this.showProductData()
	},
	filters: {
		getValue(value) { //无数据的时候返回值
			if (value == '' || value === null || value == undefined || value == 0) {
				return "无"
			} else {
				return value
			}
		},
	},
	watch:{
		choiceProductArr:function(val){
			if(val=='' ){
				this.showChoiceProduct=[]
			}
		}
	}
}