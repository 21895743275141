<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息中多处调用的弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="crm-small-layer" class="crm-connectInfo">
    <div class="connectInfo">
      <el-dialog
        :title="cntTitle"
        class="dialogThree"
        size="tiny"
        :visible.sync="test"
        :before-close="cntClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false"
      >
        <popupLoading></popupLoading>
        <div v-if="showType == 1" style="padding: 10px 0 3px 18px;">
          <el-checkbox v-model="moveMail">
            <strong style="color: red;">同时转移客户的邮件</strong>
          </el-checkbox>
        </div>
        <div class="cnt-tab" v-if="newConnect">
          <table width="100%" cellpadding="0" cellspacing="0">
            <tr>
              <td class="td-title lm">主联系人</td>
              <td class="td-info">
                <el-radio-group v-model="radio1">
                  <span @click="planShow=true">
                    <el-radio :label="1">是</el-radio>
                  </span>
                  <span @click="planShow=false">
                    <el-radio :label="2">否</el-radio>
                  </span>
                </el-radio-group>
              </td>
            </tr>
            <tr v-for="tab in newConnect">
              <td class="td-title st">{{tab.title}}</td>
              <td class="td-info">
                <input type="text" v-model="tab.info" class="importBox" />
              </td>
            </tr>
          </table>
        </div>
        <div class="cnt-tab" v-if="connectTab">
          <table width="100%" cellpadding="0" cellspacing="0">
            <tr v-for="info in connectTab">
              <td class="td-title">{{info.title}}</td>
              <td class="td-info">{{info.info}}</td>
            </tr>
          </table>
        </div>
        <div class="cnt-tab" v-if="linkList">
          <table width="100%" cellpadding="0" cellspacing="0">
            <tr>
              <td class="td-title">姓名</td>
              <td class="td-info">{{linkList.name}}</td>
            </tr>
            <tr>
              <td class="td-title">职位</td>
              <td class="td-info">{{linkList.job}}</td>
            </tr>
            <tr>
              <td class="td-title">邮箱</td>
              <td class="td-info">{{linkList.email}}</td>
            </tr>
            <tr>
              <td class="td-title">电话</td>
              <td class="td-info">{{linkList.phone}}</td>
            </tr>
            <tr>
              <td class="td-title">手机</td>
              <td class="td-info">{{linkList.tel}}</td>
            </tr>
            <tr>
              <td class="td-title">性别</td>
              <td class="td-info">{{linkList.sex}}</td>
            </tr>
            <tr>
              <td class="td-title">地址</td>
              <td class="td-info">{{linkList.address}}</td>
            </tr>
            <tr>
              <td class="td-title">网址</td>
              <td class="td-info">{{linkList.url}}</td>
            </tr>
          </table>
        </div>
        <!--转移给员工-->
        <div v-if="showType == 1" class="clientLi">
          <ul>
            <el-radio-group v-model="radioValue">
              <li v-for="(li,index) in clientList">
                <el-radio :label="li.user_id" :title="li.name">{{li.name}}</el-radio>
              </li>
            </el-radio-group>
          </ul>
        </div>
        <!--共享给员工-->
        <div v-if="showType == 2" class="clientLi">
          <ul>
            <el-checkbox-group v-model="checkboxValue">
              <li v-for="(li,index) in clientList">
                <el-checkbox class="select-btn" :label="li.user_id" :title="li.name">{{li.name}}</el-checkbox>
              </li>
            </el-checkbox-group>
          </ul>
        </div>
        <!--选择关联产品-->
        <div v-if="showType == 4" class="clientLi">
          <ul>
            <el-checkbox-group v-model="checkboxValue2">
              <li v-for="(li,index) in relationProductList">
                <el-checkbox class="select-btn" :label="li.chooseId">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :open-delay="300"
                    :content="li.name"
                    placement="right"
                  >
                    <span>{{li.product_name}}</span>
                  </el-tooltip>
                </el-checkbox>
              </li>
            </el-checkbox-group>
          </ul>
        </div>
        <div v-for="tip in littleTip" class="littleTip">
          {{tip.text1}}
          <span>{{tip.num1}}</span>
          {{tip.text2}}
          <span>{{tip.num2}}</span>
          {{tip.text3}}
          <span class="blue">{{tip.num3}}</span>
          {{tip.text4}}
        </div>
        <!--修改分类/标签管理-->
        <div v-if="showType == 3" class="tip-manage">
          <table width="100%">
            <tr v-show="hasRating">
              <td>客户星级</td>
              <td>
                <div class="block">
                  <el-rate v-model="value1"></el-rate>
                </div>
              </td>
            </tr>
            <tr>
              <td class="tabTitle">客户分类</td>
              <td class="tabSelect">
                <select class="selected" v-model="classifyid">
                  <option value="0">请选择客户分类</option>
                  <option
                    v-for="option in cusClassifyList"
                    :value="option.id"
                    :title="option.name"
                  >{{option.name1}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="tabTitle">客户来源</td>
              <td class="tabSelect">
                <select class="selected" v-model="sourceid">
                  <option value="0">请选择客户来源</option>
                  <option
                    v-for="option in cusSourceList"
                    :value="option.id"
                    :title="option.name"
                  >{{option.name1}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="tabTitle">客户关系</td>
              <td class="tabSelect">
                <select class="selected" v-model="relationid">
                  <option value="0">请选择客户关系</option>
                  <option
                    v-for="option in cusRelativeList"
                    :value="option.id"
                    :title="option.name"
                  >{{option.name1}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="tabTitle">客户状态</td>
              <td class="tabSelect">
                <select class="selected" v-model="statusid">
                  <option value="0">请选择客户状态</option>
                  <option
                    v-for="option in cusStatusList"
                    :value="option.id"
                    :title="option.name"
                  >{{option.name1}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="tabTitle">所属行业</td>
              <td class="tabSelect">
                <select class="selected" v-model="hangyeid">
                  <option value="0">请选择所属行业</option>
                  <option
                    v-for="option in belongTypeList"
                    :value="option.cid"
                    :title="option.cname"
                  >{{option.name1}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="tabTitle">关联产品</td>
              <td class="tabSelect">
                <com-product :choiceProductArr="productid" @cofirmProduct="pcpData"></com-product>
                <!-- <productClassPublic @pcpData='pcpData' :chooseProId='chooseProId'></productClassPublic> -->
                <!--<select class="selected" v-model="productid">
									<option value="0">请选择关联产品</option>
									<option v-for="option in majorProductList" :value="option.id" :title="option.name">
										<input type="checkbox" name="" id="" value="石家庄" />{{option.name1}}</option>
                </select>-->
              </td>
            </tr>
          </table>
        </div>
        <!--共享给-->
        <div class="share-layer-box" v-if="shareInfo">
          <table class="share-layer" width="100%" cellpadding="0" cellspacing="0" border="0">
            <thead>
              <tr>
                <th>序号</th>
                <th>共享人姓名</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="td in shareInfo">
                <td>
                  <!--<span>{{td.num}}</span>-->
                  <span>1</span>
                </td>
                <td>
                  <span>{{td.name}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span slot="footer" class="dialog-footer" v-if="hasButton">
          <div class="btn-box">
            <el-button type="primary" @click="cntConfirm">确 定</el-button>
            <el-button @click="cntClose">取 消</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import store from "../../../vuex/store.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import "../../../vuex/mutations.js";
import productClassPublic from "@/components/productClassPublic";
import popupLoading from "@/components/publicComponent/popupLoading";
import comProduct from "@/components/common_file/com_product/comProduct";
export default {
  name: "cm-more-info",
  data() {
    return {
      test: true,
      radio1: 1,
      radio2: 0,

      radioValue: 0, //单选框选中值
      checkboxValue: [], //多选框选中值
      checkboxValue2: [], //多选框选中值

      value1: 0, //客户星级
      classifyid: 0, //客户分类id
      sourceid: 0, //客户来源id
      relationid: 0, //客户关系id
      statusid: 0, //客户状态id
      hangyeid: 0, //行业id
      productid: '', //产品id
      relationProductList: [], //关联产品
      cusClassifyList: [], //客户分类集合
      cusSourceList: [], //客户来源集合
      cusRelativeList: [], //客户关系集合
      cusStatusList: [], //客户状态集合
      belongTypeList: [], //所属行业
      majorProductList: [], //关联产品

      myCheckbox: [],
      majorProductShow: false,

      moveMail: false //同时转移客户的邮件
    };
  },
  props: [
    "cntTitle",
    "connectTab",
    "clientList",
    "hasButton",
    "littleTip",
    "newConnect",

    "selected",
    "isCourse",
    "hasShared",
    "shareInfo",
    "linkList",

    "hasRating", //修改分类时客户星级是否显示,false隐藏,true显示
    "showType", //显示标识,1转移,2共享,3修改分类,4选择关联产品
    "productids2", //已经选择的关联产品id,多个以逗号分割
    "star", //客户星级
    "type", //客户分类
    "froms", //客户来源
    "relat", //客户关系
    "status", //客户状态
    "hangye", //客户行业
    "product", //客户产品
    "chooseProId" //选择的产品id
  ],
  computed: {
    ...mapState({
      userinfo: state => state.userinfo,
      customerclassifyall: state => state.customerclassifyall,
      customerfromall: state => state.customerfromall,
      customerrelationall: state => state.customerrelationall,
      customerstatusall: state => state.customerstatusall,
      industryType: state => state.hangyeArr,
      majorproductall: state => state.majorproductall,
      productlistall: state => state.productlistall
    })
  },
  methods: {
    ...mapMutations({
      getCustomerClassifyAll: "getCustomerClassifyAll",
      getCustomerFromAll: "getCustomerFromAll",
      getCustomerRelationAll: "getCustomerRelationAll",
      getCustomerStatusAll: "getCustomerStatusAll",
      getMajorProductAll: "getMajorProductAll",
      getProductlistAll: "getProductlistAll"
    }),
    pcpData(data) {
      this.productid = data;
    },

    cntClose: function() {
      this.$emit("cntClose", false);
    },
    cntConfirm: function() {
      this.$store.state.popupLoading = true;
      if (this.showType == 1) {
        //转移
        if (this.radioValue == 0) {
          this.$store.state.popupLoading = false;
          this.$message({
            showClose: true,
            message: "请选择目标用户！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        this.$emit("cntConfirm", this.radioValue, this.moveMail);
      } else if (this.showType == 2) {
        //共享
        if (this.checkboxValue.length == 0) {
          this.$message({
            showClose: true,
            message: "请选择目标用户！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        var userids = "";
        $.each(this.checkboxValue, function(index, row) {
          userids += row + ",";
        });
        userids = userids.substring(0, userids.length - 1);
        this.$emit("cntConfirm", userids);
      } else if (this.showType == 3) {
        //修改分类
        var parvalue =
          this.sourceid +
          "###" +
          this.statusid +
          "###" +
          this.relationid +
          "###" +
          this.productid +
          "###" +
          this.hangyeid +
          "###" +
          this.classifyid +
          "###" +
          this.value1;
        //判断客户分类的id是否是开通erp
        let isErp = this.commonJs.hasErp(this.classifyid) ? 1 : 0;
        parvalue += "###" + isErp;
        this.$emit("cntConfirm", parvalue);
      } else if (this.showType == 4) {
        //选择关联产品
        //var proids = '';
        var str = [];
        $.each(this.checkboxValue2, function(index, row) {
          var pid = row.split("###")[0];
          var pname = row.split("###")[1];
          str.push({
            id: pid,
            name: pname
          });
          //proids += row + ",";
        });
        //proids = proids.substring(0, proids.length - 1);
        this.$emit("cntConfirm", str);
      }
      this.$store.state.popupLoading = false;
      this.$emit("cntClose", false);
    }
  },
  components: {
    productClassPublic,
    popupLoading,
    comProduct
  },

  mounted() {
    if (this.showType == 3) {
      $("#crm-small-layer")
        .find(".el-dialog")
        .css("width", "370px");
    }
    var lef = this;
    if (this.showType == 3) {
      //修改分类时才加载以下信息
      if (this.customerclassifyall == "") {
        this.getCustomerClassifyAll();
      }
      var self = this;
      $.each(this.customerclassifyall, function(i, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        self.cusClassifyList.push({
          name: row.name,
          name1: name1,
          id: row.id,
          is_erp: row.is_erp
        });
      });
      if (this.customerfromall == "") {
        this.getCustomerFromAll();
      }
      $.each(this.customerfromall, function(i, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        self.cusSourceList.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
      if (this.customerrelationall == "") {
        this.getCustomerRelationAll();
      }
      $.each(this.customerrelationall, function(i, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        self.cusRelativeList.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
      if (this.customerstatusall == "") {
        this.getCustomerStatusAll();
      }
      $.each(this.customerstatusall, function(i, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        self.cusStatusList.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
      $.each(this.industryType, function(i, row) {
        var name1 = row.cname;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        self.belongTypeList.push({
          name1: name1,
          cname: row.cname,
          cid: row.cid
        });
      });
      if (this.majorproductall == "") {
        this.getMajorProductAll();
      }
      $.each(this.majorproductall, function(i, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        self.majorProductList.push({
          name1: name1,
          name: row.name,
          id: row.id
        });
      });
      this.value1 = parseInt(this.star);
      this.classifyid = parseInt(this.type);
      this.sourceid = parseInt(this.froms);
      this.relationid = parseInt(this.relat);
      this.statusid = parseInt(this.status);
      this.hangyeid = parseInt(this.hangye);
      this.productid = this.product;
    }
    if (this.showType == 4) {
      //选择关联产品时才加载以下信息
      if (this.productlistall == "") {
        this.getProductlistAll();
      }
      $.each(lef.productlistall.productclassifylist, function(i, row) {
        var product_name = row.name;
        if (product_name.length > 15) {
          product_name = product_name.substring(0, 15) + "...";
        }
        lef.relationProductList.push({
          name: row.name,
          product_name: product_name,
          id: row.id,
          chooseId: row.id + "###" + row.name
        });
      });
      if (this.productids2 != "") {
        $.each(lef.productids2.split(","), function(i, row) {
          lef.checkboxValue2.push(row);
        });
      }
    }
    function clearTransform() {
      $(".el-dialog").css({
        transform: "none"
      });
    }
    dialogDrag();
    clearTransform(dialogCenter());
  }
};
</script>
<style lang="less">
.dialog-masks {
  z-index: 2002;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  margin: 0;
}

#crm-small-layer {
  .connectInfo {
    .reProSearch {
      .el-dialog__body {
        padding: 10px 20px !important;
      }
      .el-dialog__wrapper .el-dialog {
        width: 680px;
        height: auto;
      }
    }
    .el-dialog__body {
      padding: 0px 0px !important;
    }
    .dialog-footer {
      line-height: 20px !important;
    }
    input {
      margin: 0;
    }
    .cnt-tab {
      width: 350px;
      padding: 10px 20px;
      font-size: 12px;
      height: 300px;
      overflow: auto;
      table {
        border: 1px solid #ddd;
      }
      td {
        padding: 10px 0 10px 5px;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }
      .td-title {
        background-color: #f2f2f2;
        height: 30px;
        padding-right: 10px;
        text-align: right;
        width: 50px;
      }
      .td-title.lm {
        text-align: left;
      }
      .td-info {
        max-width: 185px;
        word-wrap: break-word;
        text-align: left;
        .importBox {
          border: 1px solid #ddd;
          height: 22px;
          line-height: 22px;
          width: 180px;
        }
      }
    }
    .clientLi {
      height: 320px;
      width: 225px;
      overflow: auto;
      padding: 5px 20px 10px 0;
      text-align: left;
      font-size: 12px;
      padding-left: 18px;
      font-weight: normal;
      li {
        line-height: 26px;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        input {
          vertical-align: middle;
        }
      }
    }
    .tabSelect {
      .selected {
        margin: 5px 5px;
        width: 180px;
        height: 30px;
      }
    }
    .share-layer-box {
      width: 200px;
      padding: 10px;
      overflow: auto;
      height: 250px;
      .share-layer {
        td,
        th {
          font-weight: normal;
          width: 60px;
          height: 30px;
          border: 1px solid #ddd;
          text-align: center;
          &:nth-child(even) {
            border-left: 0;
          }
        }
        th {
          background-color: #eef1f6;
        }
      }
    }
    .el-dialog__wrapper .el-dialog {
      width: auto;
      height: auto;
    }
    .tip-manage {
      width: 370px;
      padding-left: 10px;
      td {
        height: 30px;
        padding: 0 10px;
      }
    }
  }
}
</style>