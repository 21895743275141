<template>
	<div class="productClassPublic">
		<span id="majorProductName" @click='majorProductShow=!majorProductShow' style="cursor: pointer;">
			{{myCheckbox==''?'请选择意向产品':'已选择'+myCheckbox.length+'个产品'}}
		</span>
		<transition name="fade">
			<div v-show='majorProductShow' id="newMajorProduct">
				<ul>
					<li v-for='(item,index) in majorProductList'>
						<el-checkbox-group v-model="myCheckbox">
							<el-checkbox :label="item.id" :key='index' style='display:block'>
								<el-tooltip class="item" effect="dark" :open-delay="300" :content="item.name" placement="right">
									<span style="cursor: pointer;">{{item.name1}}</span>
								</el-tooltip>
							</el-checkbox>
						</el-checkbox-group>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	export default {
		name: "productClassPublic",
		props:['chooseProId'],
		data() {
			return {
				majorProductList: [], //意向产品
				myCheckbox: [],
				majorProductShow: false,
			}
		},
		watch: {
			majorProductShow(newv) {
				let self = this,
					data = '';
				if(!newv) {
					if(self.myCheckbox.length > 0) {
						$.each(self.myCheckbox, (i, v) => {
							data += v + ',';
						})
						data = data.substring(0, data.length - 1)
					} else {
						data = 0
					}
					this.$emit('pcpData', data)
				} else {
					var top = Math.round(Math.round($('.productClassPublic').offset().top) - $('.productClassPublic').parents('.el-dialog').eq(0).offset().top + Math.round($('.productClassPublic').height()) / 2 + 5);
					$("#newMajorProduct").css("top", top + 'px');
				}
			}
		},
		computed: {
			...mapState({
				majorproductall: state => state.majorproductall,
			}),
		},
		methods: {
			...mapMutations({
				getMajorProductAll: 'getMajorProductAll',
			}),
		},
		mounted() {
			var lef = this;
			if(this.chooseProId != 0 && this.chooseProId != "" && this.chooseProId != undefined && this.chooseProId != null){
				$.each(this.chooseProId.split(','), function(i, row) {
					lef.myCheckbox.push(parseInt(row));
				});
			}
			
			var scrollFunc = function(e) {
				e = e || window.event;
				if($(e.target).parents('#newMajorProduct').index() != 1) {
					if(e.wheelDelta) { //IE/Opera/Chrome 
						lef.majorProductShow = false
					} else if(e.detail) { //Firefox  
						lef.majorProductShow = false
					}
				}
			}
			if(document.addEventListener) {
				document.addEventListener('DOMMouseScroll', scrollFunc, false);
			} //W3C  
			window.onmousewheel = document.onmousewheel = scrollFunc; //IE/Opera/Chrome

			$("body").click(function(e) {
				var target = $(e.target);
				if(!target.is('#newMajorProduct *') && lef.majorProductShow && target.attr('id') != 'majorProductName') {
					lef.majorProductShow = false
				}
			})

			if(this.majorproductall == '') {
				this.getMajorProductAll();
			}
			$.each(this.majorproductall, function(i, row) {
				var name1 = row.name;
				if(name1.length > 12) {
					name1 = name1.substring(0, 12) + "...";
				}
				lef.majorProductList.push({
					name1: name1,
					name: row.name,
					id: row.id,
				})
			})

		}
	}
</script>
<style type="text/css">
	.productClassPublic .el-checkbox__label {
		display: inline;
	}
</style>
<style scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity .5s;
	}
	
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
	
	.productClassPublic li {
		line-height: 26px;
	}
	
	.productClassPublic {
		padding-left: 5px;
	}
	
	#newMajorProduct {
		z-index: 100;
		width: 180px;
		max-height: 300px;
		overflow-y: auto;
		margin: 5px 5px 5px -5px;
		position: absolute;
		top: 25px;
		padding: 5px 5px;
		border: 1px solid rgb(191, 203, 217);
		border-radius: 3px;
		background-color: #fff;
	}
</style>