import { render, staticRenderFns } from "./comProduct.vue?vue&type=template&id=4aa0f9f8&"
import script from "@/js/common_file/com_product/comProduct.js?vue&type=script&lang=js&"
export * from "@/js/common_file/com_product/comProduct.js?vue&type=script&lang=js&"
import style0 from "@/css/comFile.css?vue&type=style&index=0&lang=css&"
import style1 from "./comProduct.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports