<template>
	<div class="popupLoading" v-if='popupLoading'>
		<div class="plMain">
			<i class="el-icon-loading plIcon"></i>
			<p class="plTip">加载中，请稍后....</p>
		</div>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
	export default {
		name: 'popupLoading',
		data() {
			return {}
		},
		methods: {},
		computed: {
			...mapState(['popupLoading']),
		},
		mounted() {
//			import popupLoading from '@/components/publicComponent/popupLoading'
//			<popupLoading></popupLoading>
//			components: {
//		popupLoading
//	},
//			beforeSend: function() {
//					self.$store.state.popupLoading = true;
//				},
//				complete: function() {
//					self.$store.state.popupLoading = false;
//				},
//				
//				beforeSend: function() {
//					lef.$store.state.popupLoading = true;
//				},
//				complete: function() {
//					lef.$store.state.popupLoading = false;
//				},
		}
	}
</script>

<style scoped>
	.popupLoading {
		position: absolute;
		z-index: 999;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #fff;
	}
	
	.plMain {
		position: absolute;
		top: 50%;
		right: 50%;
		width: 160px;
		height: 70px;
		margin: -35px -80px 0 0;
		text-align: center;
	}
	
	.plIcon {
		font-size: 32px;
		color: #20A0FF;
	}
	
	.plTip {
		margin-top: 10px;
		font-size: 16px;
		color: #20A0FF;
	}
</style>